import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { SectionHeader } from 'components/molecules';
import { Section } from 'components/organisms';
import { graphql, useStaticQuery } from 'gatsby'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
    position: 'relative',
    background: `url(https://assets.maccarianagency.com/the-front/illustrations/contact-cover.svg) no-repeat #3F50B5`,
    overflow: 'hidden',
    minHeight: 400,
    [theme.breakpoints.up('md')]: {
      backgroundPosition: 'right -400px top',
    },
    [theme.breakpoints.up('lg')]: {
      backgroundPosition: 'right -250px top',
    },
  },
  textWhite: {
    color: 'white',
  },
  title: {
    fontWeight: 'bold',
  },
  section: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    paddingTop: 0,
    paddingBottom: 0,
  },
  sectionHeader: {
    [theme.breakpoints.up('sm')]: {
      width: '50%',
    },
  },
}));

const Hero = props => {
  const data = useStaticQuery(graphql`
    query {
      pages: allContentfulPages {
        edges {
          node {
            title
            slug
          }
        }
      }
      header: allContentfulHeader(filter: {id: {eq: "6e19638e-d415-5981-865e-bde80a3583aa"}}) {
          edges {
            node {
              title
              subTitle
              slug
              image {
                fluid(maxHeight: 600, maxWidth: 1400) {
                  src
                }
              }
              firstButton
              secondButton
            }
          }
        }
      }
  `)
  const { className, ...rest } = props;
  const classes = useStyles();
  return (
    <div className={clsx(classes.root, className)} {...rest}>
      {data.header.edges.map((edge) => {
        const info = edge.node;
        return(
          <header>
            <Section className={classes.section}>
              <SectionHeader
                title={info.title}
                subtitle={info.subTitle}
                align="left"
                data-aos="fade-up"
                disableGutter
                titleProps={{
                  className: clsx(classes.title, classes.textWhite),
                  variant: 'h3',
                }}
                subtitleProps={{
                  className: classes.textWhite,
                }}
                className={classes.sectionHeader}
              />
            </Section>
          </header>
        )
      })}
    </div>
  );
};

Hero.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default Hero;
